import { getPage } from "../data-interface/sanity";
import { useEffect, useRef } from "react";
import NextLink from "next/link";
import gsap from "gsap";
import Cube from "../components/Cube/Cube";
import styles from "./404.module.scss";
import CtaArrow from "../assets/svgs/cta-arrow.svg";
import useBreakpoint from "../utils/hooks/use-breakpoint";

export default function Page404() {
    const cubeRef = useRef();
    const tl = useRef();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (tl.current) tl.current.kill();

        tl.current = gsap.timeline({ repeat: -1 });
        const duration = 2;

        tl.current.to(cubeRef.current, {
            "--box-offset": isMobile ? "2.5rem" : "5rem",
            x: isMobile ? "1.25rem" : "2.5rem",
            ease: "linear",
            duration,
        });

        tl.current.to(cubeRef.current, {
            "--box-offset": 0,
            x: 0,
            ease: "linear",
            duration,
        });

        return () => {
            if (tl.current) tl.current.kill();
        };
    }, [isMobile]);

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <Cube
                    ref={cubeRef}
                    className={styles.cube}
                    frontContent={
                        <div className={styles.front}>
                            <h1 className={styles.title}>404</h1>
                            <p className={styles.text}>
                                this page doesn’t live here anymore, or maybe it
                                never did
                            </p>
                            <NextLink href="/">
                                <div className={styles.button}>
                                    <span className={styles.buttonText}>
                                        go home
                                    </span>
                                    <CtaArrow className={styles.buttonArrow} />
                                </div>
                            </NextLink>
                        </div>
                    }
                    backContent={<div className={styles.backContent} />}
                />
            </div>
        </div>
    );
}

export const getStaticProps = async (context) => {
    const { preview: isPreview = false } = context;
    const slug = "404";

    const pageData = await getPage(slug);

    if (!pageData) {
        return {
            notFound: true,
        };
    }

    return {
        props: {
            pageData,
            isPreview,
        },
        revalidate: parseInt(process.env.REVALIDATE),
    };
};
